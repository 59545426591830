import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  FeatureListItem,
  Seo,
  MainLayout,
  Button,
  Highlight,
  CalloutBox,
  CalloutBoxTitle,
  CalloutBoxText,
  CalloutBoxButtons,
} from "../components";
import { FeatureSection } from "../components/FeatureSection";
import { HomePageSection } from "../components/HomePageSection";
import { TestimonialBlock } from "../components/TestimonialBlock";

const HomePage = () => {
  return (
    <MainLayout>
      <Seo />
      {/* Hero */}
      <section className="overflow-hidden mb-8">
        <div className="container flex flex-col pt-md md:pt-xl md:px-2xl items-center text-center gap-8">
          <h1 className="text-4xl md:text-5xl lg:text-6xl tracking-[-2px] max-w-2xl">
            Build better business applications,{" "}
            <span className="text-red-to-yellow">faster.</span>
          </h1>

          <p className="text-xl max-w-xl">
            No more juggling REST endpoints or deciphering GraphQL queries.
            Hilla seamlessly connects <span className="text-green-400 font-semibold">
            Spring Boot</span> and <span className="text-blue-400 font-semibold">
            React</span> to accelerate application development.
          </p>

          <Button
            className="mt-8"
            href="/docs/react/start/basics"
            variant="primary"
            size="large"
          >
            Get Started
          </Button>
        </div>
      </section>

      {/* Main Features */}
      <HomePageSection>
        <FeatureSection title="Call Java services directly from React">
          <p>
            Use <code>@BrowserCallable</code> to make a Spring {" "}
            <code>@Service</code> class available to your browser.
            Hilla uses Spring Security to ensure only the right people have access.
          </p>
          <Highlight language="java">
            {`
              @BrowserCallable
              @Service
              public class CustomerService {
                  public List<Customer> getCustomers() {
                      // Fetch customers from DB or API
                  }
              }
            `}
          </Highlight>

          <p className="mt-8">
          Access the service from TypeScript through a simple async method call,
          type information included.
          </p>

          <Highlight language="tsx">
            {`
              function CustomerList() {
                // Customer type is automatically generated by Hilla
                  const [customers, setCustomers] = useState<Customer[]>([]);

                  useEffect(() => {
                      CustomerService.getCustomers().then(setCustomers);
                  }, []);

                  return (
                      <ComboBox items={customers} />
                  )
              }
            `}
          </Highlight>
        </FeatureSection>

        <FeatureSection title="Move fast without breaking stuff with end-to-end type safety">
          <p>
            Spend less time reading API docs and more time coding. Automatically
            generated TypeScript services and data types allow you to explore
            APIs right in your IDE. Breaking changes to the API leads to
            compile-time errors instead of runtime errors in the frontend.
          </p>

          <StaticImage
            src="../images/home/auto-complete.png"
            alt="Automatically complete code in IDE"
          />
        </FeatureSection>

        <FeatureSection title="Ensure data consistency with shared form validation logic">
          <p>
            Define validation rules in the Java model object, Hilla will automatically
            reuse them in your React form. On form submit, Hilla automatically
            re-validates the input in your Spring service.
          </p>

          <Highlight language="java">
            {`
              public class Customer {

                @NotBlank(message = "Name is mandatory")
                private String name;

                @NotBlank(message = "Email is mandatory")
                @Email
                private String email;

                // Getters and setters
              }
            `}
          </Highlight>

          <p className="mt-8">
            The Hilla <code>useForm</code> hook uses the generated TypeScript
            model to apply the validation rules to the form fields.
          </p>
          <Highlight language="java">
            {`
              function CustomerForm() {

                const {model, field, submit} = useForm(CustomerModel, {
                    onSubmit: CustomerService.saveCustomer
                });

                return (
                <div>
                  <TextField label="Name" {...field(model.name)} />
                  <EmailField label="Email" {...field(model.email)} />
                  <Button onClick={submit}>Save</Button>
                </div>
                )
              }
            `}
          </Highlight>
        </FeatureSection>

        <FeatureSection title="UI components included">
          <p>
            Hilla comes with an arsenal of pre-built UI components designed for
            complex, data-heavy business applications. Easily customize forms,
            data grids, editors, and more to fit your brand. All components
            are rigorously tested to ensure WCAG 2.2 AA accessibility.
          </p>

          <StaticImage
            src="../images/home/new-component-image.png"
            alt="Vaadin components"
          />

          <p>
            <span className="font-bold">No lock-in:</span> Hilla supports any
            React components, so you can also bring your own or mix and match as
            needed.
          </p>
        </FeatureSection>

        <FeatureSection title="Stream live updates to the browser with ease">
          <p>
            With support for reactive data types, you can build real-time
            dashboards, notifications, and more without managing websockets yourself.
          </p>

          <p>
            To stream data from the Spring Boot backend to the React frontend,
            return a Project Reactor Flux data type from your service.
          </p>

          <Highlight language="java">
            {`
              @BrowserCallable
              @Service
              public class CustomerService {
                  public Flux<Customer> getOnlineCustomers() {
                      return Flux.just(/*...Online customers...*/);
                  }
              }
            `}
          </Highlight>

          <p className="mt-8">
            Then subscribe to the stream and update the client state as new data
            comes in.
          </p>

          <Highlight language="tsx">
            {`
              function OnlineCustomers() {
                  const [onlineCustomers, setOnlineCustomers] = useState<Customer[]>([]);

                  // Instead of awaiting a single response, subscribe to the stream of data
                  useEffect(() => {
                      CustomerService.getOnlineCustomers().onNext(setOnlineCustomers);
                  }, []);

                  return (
                      <AvatarGroup
                        items={onlineCustomers.map((customer) => ({
                            name: customer.name,
                            img: customer.profilePicture
                        }))}
                      />
                  )
              }
            `}
          </Highlight>
        </FeatureSection>
      </HomePageSection>

      <HomePageSection
        title="Join the growing community behind Hilla"
        subtitle="Open Source"
      >
        <FeatureSection>
          <p className="items-center text-center">
            With a thriving community of developers, it's easy to see why Hilla
            is quickly becoming a go-to solution for building robust business
            applications.
          </p>
          <div className="flex gap-4 mx-auto">
            <a href="https://github.com/vaadin/hilla" target="_blank">
              <img
                src="https://img.shields.io/github/stars/vaadin/hilla?style=social"
                alt="Hilla GitHub Repo stars"
              />
            </a>
            <a href="https://discord.gg/vaadin" target="_blank">
              <img
                src="https://img.shields.io/discord/732335336448852018?label=Discord&logo=discord&logoColor=white&color=7389D8"
                alt="Hilla Discord Server"
              />
            </a>
            <a href="https://twitter.com/hillaframework" target="_blank">
              <img
                src="https://img.shields.io/twitter/url?label=Follow&style=social&url=https%3A%2F%2Ftwitter.com%2Fhillaframework"
                alt="Twitter Follow"
              />
            </a>
          </div>

          <h3 className="mt-8 items-center text-center">
            What are other developers saying?
          </h3>

          <div className="grid md:grid-cols-2 gap-8 mt-8">
            <TestimonialBlock
              quote="I regularly complain on the lack of full-stack framework for Java. There are great backend frameworks, but frontend always felt as second class citizen involving glueing together 3rd party libraries. This isn't true anymore. Check @hillaframework"
              link="https://twitter.com/maciejwalkowiak/status/1592931251832623104"
              name="Maciej Walkowiak"
              title="Spring Academy Founder"
            />
            <TestimonialBlock
              quote="The fact that the client-server communication code and type-safe model include validation rule generation, makes it superior to other solutions with traditional client frameworks such as Angular or React."
              link="https://vaadin.com/blog/simon-martinelli-on-hilla-the-type-safe-web-framework-for-java"
              name="Simon Martinelli"
              title="Founder of 72 Services"
            />
            <TestimonialBlock
              quote="Hilla is on the short list of projects I need to check out 🤩"
              link="https://twitter.com/therealdanvega/status/1633479928669691906"
              name="Dan Vega"
              title="Senior Developer Advocate at VMware Tanzu"
            />
            <TestimonialBlock
              quote="[Hilla] gives you a Spring Boot back-end that's optimized to easily expose your services in a way that those front-end applications can consume"
              link="https://spring.io/blog/2022/03/09/spring-tips-hilla-an-amazing-new-web-framework-from-vaadin"
              name="Josh Long"
              title="Spring Developer Advocate at VMware Tanzu"
            />
          </div>
        </FeatureSection>
      </HomePageSection>

      <HomePageSection
        title="More reasons to choose Hilla"
        subtitle="Still not convinced?"
      >
        <div className="mt-md max-w-4xl mx-auto grid md:grid-cols-2 gap-12">
          <FeatureListItem
            title="Powerful forms with shared validations"
            iconClass="las la-check-square"
          >
            <p>
              Add Java bean validations to your server-side objects, and Hilla
              will automatically apply those validations on the client side.
              Plus, re-validation on the server ensures data integrity.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Stateless and scalable"
            iconClass="las la-server"
          >
            <p>
              Your Hilla app stays nimble and cost-effective, scaling easily
              because it's stateless by default. You focus on growing your
              business; we ensure your app can handle it.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Secure communication out-of-the-box"
            iconClass="las la-lock"
          >
            <p>
              Hilla uses Spring Security to enable access control features by
              default for all your endpoint methods. Build secure apps without
              the boilerplate.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Built-in support for streaming data"
            iconClass="las la-sync-alt"
          >
            <p>
              Build apps that are as dynamic as your business. Hilla fully
              supports reactive Flux and Mono data types for seamless real-time
              experiences.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Works with React and Lit frontends"
            iconClass="las la-code"
          >
            <p>
              Hilla also supports building the frontend with the Google Lit
              library, if you prefer. This allows you to build the entire app
              using web component standards.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="TypeScript autocompletion"
            iconClass="las la-keyboard"
          >
            <p>
              Say goodbye to constant API doc browsing. Hilla's automatic
              TypeScript type generation lets you explore your API right within
              your IDE, speeding up development.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Truly open source"
            iconClass="las la-code-branch"
          >
            <p>
              Extend, modify, or even contribute to Hilla. Hilla is Apache 2.0
              licensed and hosted on a public{" "}
              <a href="https://github.com/vaadin/hilla" className="link">
                GitHub repository
              </a>
              . You're encouraged to engage with the code and the community!
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="A web framework designed for Spring Boot"
            iconClass="las la-cogs"
          >
            <p>
              Hilla is designed to work hand-in-glove with Spring Boot. Enjoy a
              smooth developer experience with pre-configured Maven and Vite
              setups and real-time code reloading via Live Reload.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Enterprise support options"
            iconClass="las la-user-shield"
          >
            <p>
              For those requiring a bit more, choose from our{" "}
              <a href="https://vaadin.com/pricing/hilla" className="link">
                commercial subscriptions
              </a>
              . Get expert support and gain access to premium features like
              Charts, Grid Pro, and more.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Your brand, your look"
            iconClass="las la-palette"
          >
            <p>
              Customize the included UI components easily through exposed
              styleable parts and CSS custom properties. The included{" "}
              <a
                href="https://www.figma.com/community/file/843042473942860131"
                className="link"
              >
                Figma design kit
              </a>{" "}
              ensures designers and developers speak the same language for
              smooth handovers.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Accessible components"
            iconClass="las la-wheelchair"
          >
            <p>
              Everyone deserves better business apps. That's why the included
              Vaadin UI components are continuously tested to ensure good
              support for assistive devices and technologies.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Single Sign-On Support"
            iconClass="las la-sign-in-alt"
          >
            <p>
              Easily integrate your Hilla app with third-party identity
              providers for a seamless user experience. Our{" "}
              <a
                href="https://hilla.dev/docs/react/acceleration-kits/sso-kit"
                className="link"
              >
                SSO Kit
              </a>
              , part of the Acceleration Kits, is available for commercial use.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="GraalVM native image support"
            iconClass="las la-bolt"
          >
            <p>
              Compile your Hilla application into a GraalVM native image for
              lightning-fast startup times and minimal resource use.
            </p>
          </FeatureListItem>

          <FeatureListItem
            title="Installable on any device"
            iconClass="las la-mobile"
          >
            <p>
              You can configure your Hilla application to generate a PWA
              ServiceWorker and manifest file with a simple annotation, making
              your app installable on any device.
            </p>
          </FeatureListItem>
        </div>
      </HomePageSection>

      <section className="pt-md pb-xl md:py-xl max-w-5xl mx-auto container">
        <CalloutBox>
          <CalloutBoxTitle>
            Ready to build better business applications, faster?
          </CalloutBoxTitle>

          <CalloutBoxText>Get up and running in minutes.</CalloutBoxText>

          <CalloutBoxButtons>
            <Button
              className="mt-4"
              href="/docs/react/start/basics"
              size="large"
              variant="primary"
            >
              Get Started
            </Button>
          </CalloutBoxButtons>
        </CalloutBox>
      </section>
    </MainLayout>
  );
};

export default HomePage;
